<template>
    <AModal
        :visible="visible"
        :title="state.title"
        @cancel="handleModalCancel"
        :footer="null">
        <AAlert type="success" :message="`Sukses: ${state.total.valid}`"></AAlert>
        <AAlert type="error">
            <template #message>
                {{ `Gagal: ${state.total.failed}` }}
                
                <p
                    v-for="(item, index) in state.data.reject"
                    :key="index">
                    {{ item.message }}
                </p>
            </template>
        </AAlert>
    </AModal>
</template>
<script>
import {
    defineComponent,
    reactive,
    watch,
} from 'vue'

// example data errors
/**
 * data: [
 *  {
 *      message: 'Title 1',
 *      status: true,
 *  },
 *  {
 *      message: 'Title 2',
 *      status: false,
 *  },
 * ]
 */

export default defineComponent({
    props: {
        visible: [Boolean],
        data: {
            type: Array,
            default: () => ([]),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            title: 'Result',
            data: {
                reject: [],
            },
            total: {
                valid: 0,
                failed: 0,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        // handle func vue
        watch(() => props.data, (newVal) => {
            state.data.reject = []
            state.total.valid = 0
            state.total.failed = 0

            newVal.forEach((item) => {
                if (item.status) {
                    state.total.valid += 1
                } else {
                    state.total.failed += 1
                    state.data.reject.push(item)
                }
            })
        })

        return {
            state,
            handleModalCancel,
        }
    },
});
</script>
