<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterGudang
                    style="width:100%;"
                    v-model:distributor="state.params.distributor"
                    v-model:value="state.params.gudang"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProduct
                    style="width:100%;"
                    v-model:region="state.params.region"
                    v-model:value="state.params.product"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    style="width:100%"
                    :options="state.statusLists"
                    v-model:value="state.params.status"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    v-model:value="state.params.adjustment_code"
                    placeholder="Cari kode adjustment..."
                    allow-clear
                    style="width:100%"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        title="Simpan">
                        <AButton
                            :loading="state.action.loading"
                            @click="btnActionRequest()"
                            type="primary">
                            <span class="fa fa-send" aria-hidden="true"/>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                row-key="id"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #action="{ index, record }">
                    <ARadioGroup
                        v-model:value="record.action_status"
                        @input="listenActionButton"
                        :key="index">
                        <ASpace>
                            <ATooltip title="Approve">
                                <ARadioButton
                                    class="approve"
                                    shape="circle"
                                    :id="record.adjustment_code"
                                    :disabled="record.isRequest"
                                    value="approved">
                                    <i class="fa fa-check" aria-hidden="true"/>
                                </ARadioButton>
                            </ATooltip>
                            <ATooltip title="Reject">
                                <ARadioButton
                                    class="reject"
                                    shape="circle"
                                    :id="record.adjustment_code"
                                    :disabled="record.isRequest"
                                    value="rejected">
                                    <i class="fa fa-times" aria-hidden="true"/>
                                </ARadioButton>
                            </ATooltip>
                        </ASpace>
                    </ARadioGroup>
                </template>
    
                <template
                    #note="{ index, record }">
                    <span v-if="record.isRequest">{{ record.reason }}</span>
                    <ATextarea
                        v-else
                        v-model:value="record.reason"
                        :key="index"
                        placeholder="Reason"
                        allow-clear
                        :disabled="!record.isReason"
                        :id="record.adjustment_code"
                        @input="listenActionNote"/>
                </template>

                <template #expandedRowRender="{ record }">
                    <ATable
                        :columns="state.columnsDetails"
                        :data-source="record.details"
                        :pagination="false"/>
                </template>
            </MdTable>
        </div>
        
        <!-- message errors -->
        <MessageErrors
            v-model:visible="state.errors.visible"
            :data="state.errors.data"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, h } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterGudang from '@/components/filter/FilterGudang'
import FilterProduct from '@/components/filter/FilterProduct'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import MessageErrors from '@/components/Molecules/MessageErrors'
import _ from 'lodash'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'

export default defineComponent({
    components: {
        DownloadExcel,
        MessageErrors,
        FilterDistributor,
        FilterGudang,
        FilterProduct,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    slots: { customRender: 'action' },
                },
                {
                    title: 'Note',
                    width: 200,
                    slots: { customRender: 'note' },
                },
                {
                    title: 'Kode Adjustment',
                    dataIndex: 'adjustment_code',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributor',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'warehouse_code',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'warehouse',
                },
                {
                    title: 'Status Gudang',
                    dataIndex: 'warehouse_status',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Catatan',
                    dataIndex: 'notes',
                    customRender: ({ text }) => h("div", { innerHTML: decodeHtml(text) }),
                },
            ],
            columnsDetails: [
                {
                    title: 'Kode Produk',
                    dataIndex: 'product_code',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'product',
                },
                {
                    title: 'Tipe Adjustment',
                    dataIndex: 'adjustment_type',
                },
                {
                    title: 'QTY',
                    dataIndex: 'quantity',
                },
            ],
            endpoint: {
                current: '/api/report/adjustment-stock-approvals',
                request: '/api/report/adjustment-stock-approvals',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                distributor: [],
                gudang: [],
                product: [],
                status: 'waiting',
                adjustment_code: null,
                page: 1,
                "per-page": 10,
            }),
            action: {
                loading: false,
                data: [],
            },
            errors: {
                visible: false,
                data: [],
            },
            statusLists: [
                {
                    label: "Waiting",
                    value: 'waiting',
                },
                {
                    label: "Approved",
                    value: 'approved',
                },
                {
                    label: "Rejected",
                    value: 'rejected',
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.details = JSON.parse(item.details)
                        item.isRequest = item.status != 'waiting'
                        item.isReason = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnActionRequest = () => {
            if (state.action.data.length === 0) {
                Modal.warning({
                    content: 'Centang dahulu data yang akan diperbarui!',
                })
                return
            }

            state.action.loading = true

            apiClient.put(state.endpoint.request, {
                    data: state.action.data,
                })
                .then(({ data }) => {
                    state.errors.visible = true
                    state.errors.data = [
                        ...data.errors.map(item => ({
                            message: Array.isArray(item.message) ? item.message[0] : item.message,
                            status: false,
                        })),
                        ...data.success.map(() => ({
                            status: true,
                        })),
                    ]

                    fetchDataList()
                })
                .catch((error) => {
                    errorMessage.value = error.response.data.message
                })
                .finally(() => {
                    state.action.loading = false
                    state.action.data = []
                })
        }

        const listenActionButton = (event) => {
            let id = event.target.getAttribute('id')
            let status = event.target.value

            let find = state.action.data.find(item => item.adjustment_code == id)
            let first = state.data.find(item => item.adjustment_code == id)

            if (!find && first) {
                state.action.data.push({
                    status,
                    adjustment_code: first.adjustment_code,
                })
            } else {
                find.status = status
            }

            // enabled reason for status rejected
            first.isReason = status == 'rejected'
        }

        const listenActionNote = _.debounce((event) => {
            let id = event.target.getAttribute('id')

            let find = state.action.data.find(item => item.adjustment_code == id)
            if (find) {
                find.reason = event.target.value
            } else {
                state.action.data.push({
                    adjustment_code: id,
                    reason: event.target.value,
                })
            }
        }, 300)

        const decodeHtml = (html) => {
            const textarea = document.createElement("textarea")
            textarea.innerHTML = html
            return textarea.value
        }


        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            errorMessage,
            queryParams,
            fetchDataList,
            handleTableChange,
            btnActionRequest,
            listenActionButton,
            listenActionNote,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
});
</script>

<style scoped lang="scss">
.approve,
.reject {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;

    &.ant-radio-button-wrapper-checked {
        color: white !important;
    }
}
.approve {
    padding: 0px 8px !important;
    color: #009688;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #009688 !important;
        border-color: #009688 !important;
    }
}
.reject {
    padding: 0px 10px !important;
    color: #f44336;
    
    &.ant-radio-button-wrapper-checked {
        background-color: #f44336 !important;
        border-color: #f44336 !important;
    }
}
</style>